<script lang="ts">
    import { onMount } from "svelte";
    import L from "leaflet";
    import WelcomeModal from "./WelcomeModal.svelte";
    import { initMap } from "./map";
    import ExploreButton from "./ExploreButton.svelte";
    import ContentLog from "./ContentLog.svelte";

    let map: L.Map;
    let sessionId: string | null = null;

    async function createOrUpdateSession() {
        try {
            const response = await fetch('/api/session.php', { 
                method: 'POST',
                credentials: 'include'  // This is important for sending cookies
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            sessionId = data.sessionId;
            console.log("session", sessionId);
        } catch (error) {
            console.error('Error creating/updating session:', error);
        }
    }

    async function updateSession() {
        if (sessionId) {
            try {
                const response = await fetch('/api/session.php', {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ sessionId }),
                    credentials: 'include'  // This is important for sending cookies
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('Error updating session:', error);
            }
        }
    }

    onMount(() => {
        map = initMap('map');
        createOrUpdateSession();

        // Set up an interval to update the session every 5 minutes
        const intervalId = setInterval(updateSession, 5 * 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    });
</script>

<main>
    <div id="map"></div>
    <ExploreButton />
    <ContentLog />
    <WelcomeModal />
</main>

<style>
    :global(body, html) {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
    }

    main {
        height: 100vh;
        width: 100vw;
    }

    #map {
        height: 100%;
        width: 100%;
    }
</style>
