import L from "leaflet";
export function initMap(elementId) {
    const mapOptions = {
        zoomControl: false,
    };
    const map = L.map(elementId, mapOptions).setView([51.505, -0.09], 13);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
    return map;
}
export function addMarker(map, lat, lon, popupContent) {
    const marker = L.marker([lat, lon]).addTo(map);
    marker.bindPopup(popupContent);
    return marker;
}
