<script lang="ts">
    import { onMount } from 'svelte';
    import { fade } from 'svelte/transition';

    let visible = false;

    onMount(() => {
        const hasSeenModal = localStorage.getItem('hasSeenWelcomeModal');
        if (!hasSeenModal) {
            visible = true;
            localStorage.setItem('hasSeenWelcomeModal', 'true');
        }
    });

    function closeWelcome() {
        visible = false;
    }
</script>

{#if visible}
    <div class="modal" tabindex="-1" role="dialog" transition:fade>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Welcome to our Map Application!</h5>
                    <button type="button" class="close" on:click={closeWelcome}>
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>This is a brief introduction to our application. You'll only see this message once.</p>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-primary"
                        on:click={closeWelcome}>Get Started</button
                    >
                </div>
            </div>
        </div>
    </div>
{/if}

<style>
    .modal {
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
    }
</style>
