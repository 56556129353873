<script lang="ts">
    export function explore() {
        // Implement exploration functionality here
        console.log("Explore mode activated");
    }
</script>

<button on:click={explore} class="explore-button"> Explore </button>

<style>
    .explore-button {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1000;
        padding: 10px 20px;
        background-color: #3498db;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
    }

    .explore-button:hover {
        background-color: #2980b9;
    }
</style>
