<script lang="ts">
    import { onMount } from "svelte";

    let isOpen = false;
    let isSmallScreen = false;

    function toggleOpen() {
        isOpen = !isOpen;
    }

    function checkScreenSize() {
        isSmallScreen = window.innerWidth < 768; // Adjust this breakpoint as needed
    }

    onMount(() => {
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);
        return () => window.removeEventListener("resize", checkScreenSize);
    });
</script>

{#if isOpen}
    <div class="content-log open" class:small-screen={isSmallScreen}>
        <button class="close-button" on:click={toggleOpen}>&times;</button>
        <div class="content">
            <!-- Add your log content here -->
            <p>This is where your log entries or content will appear.</p>
        </div>
    </div>
{:else}
    <button class="open-button" on:click={toggleOpen}>Open Log</button>
{/if}

<style>
    .content-log {
        position: absolute;
        bottom: 20px;
        right: 20px;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        transition: all 0.3s ease;
    }

    .content-log.open {
        width: 50%;
        height: 80vh;
    }

    .content-log.open.small-screen {
        width: 90%;
        height: 90vh;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
    }

    .open-button {
        position: absolute;
        bottom: 20px;
        right: 20px;
        padding: 10px 20px;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        z-index: 1000;
    }

    .content {
        padding: 20px;
        height: 100%;
        overflow-y: auto;
    }
</style>
